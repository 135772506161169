import { AxiosPromise, CancelToken } from 'axios';
import { OutreachTemplateType } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import {
  BaseCallback,
  BasePagination,
  EmptyResponseCallback,
  PaginatedFilters,
  PaginatedResponse,
} from 'clients/types';
import { CandidateActivityLogStatus, CandidateStatus } from 'services/candidates';
import { CandidateActionFriendly, CandidateSecondaryActionFriendly } from 'services/candidates/types';
import { HistoryContactMethod } from 'shared/contexts/CandidateContext/searchv3/types';
import { Filters } from 'shared/contexts/SearchContext/FiltersContext/types';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { ICampaign } from 'types/campaign';
import { CandidateResponse } from './CandidateResponse';

/////////////////////////////
/// General
/////////////////////////////

export enum CampaignOverviewStatus {
  active = 'active',
  inactive = 'inactive',
}

type CampaignUser = {
  full_name: string;
  initials: string;
};

export type RatedCandidates = {
  id: number;
  es_person_id: string;
};

export interface CampaignResponse {
  id: number;
  ats_id: string | null;
  ats_name: string | null;
  is_ats_configured?: boolean;
  tenant_id: number;
  tenant_domain: string;
  user: CampaignUser;
  name: string;
  rated_candidates: RatedCandidates[];
  legacy_assigment_id?: number;
  created_at: Date;
  updated_at: Date;
  status: string;
  last_viewed?: Date;
  last_synced?: Date;
  total_candidates_count: number;
  new_candidates_count?: number;
  approved_candidates_count?: number;
  rejected_candidates_count?: number;
  rated_candidates_count?: number;
  result_callback_url?: string;
  current_filter: {
    filters: Filters;
  };
}

export interface CampaignFacetsResponse {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: null;
    hits: never[];
  };
  aggregations: {
    source_facets: {
      doc_count: number;
      source_facet: {
        doc_count_error_upper_bound: number;
        sum_other_doc_count: number;
        buckets: Array<{
          key: CampaignJobboard;
          doc_count: number;
        }>;
      };
    };
  };
}

export type CampaignOverviewResponse = {
  id: number;
  name: string;
  new_candidates_count: number;
  full_owner_name: string;
};

export interface CountResponse {
  count: number;
}

export interface CampaignPage {
  count: number;
  next: string | null;
  previous: string | null;
  results: CampaignResponse[];
}

export type GetCountCampaignsHandler = (params: GetCountCampaignsParams) => AxiosPromise<CountResponse>;
export type GetCountCampaignsCandidatesHandler = () => AxiosPromise<CountResponse>;

export interface GetCountCampaignsParams {
  status: string;
  user_id?: number;
}
export interface CampaignCandidateResponse {
  id: number;
  es_person_id: string;
  legacy_person_id?: string;
  status: CandidateStatus;
  is_unlocked?: boolean | null;
  campaign?: Omit<CampaignResponse, 'created_at' | 'current_filter' | 'rated_candidates' | 'updated_at'>;
  candidate: CandidateResponse;
  created_at: Date;
  updated_at: Date;

  reasons?: string[] | null;
}

export interface CampaignCreateBody {
  name: string;
  vacancyUrl: string;
  vacancyDocumentIdentifier: string;
  atsVacancy: {
    name: string;
    value: string;
  };
  isATSConfigured: boolean;
  filters?: Filters;
}

export interface CampaignFiltersBody {
  filters: Filters;
}

export type NotifyCandidateStatus = 'success';

export type NotifyCandidateMethod = OutreachTemplateType | 'call';

export type NotifyCandidateData = {
  subject?: string;
  message?: string;
  sign_off?: string;
  email?: string;
  phone_number?: string;
  website?: string;
  status?: NotifyCandidateStatus;
};

type ActivityLogUser = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

export type CandidateActivityLogMetadata =
  | {
      action: Extract<CandidateActivityLogStatus, 'CAMPAIGN_CANDIDATE_LABEL_CHANGED'>;
      metadata: {
        added_labels: string[];
        original: string[];
        removed_labels: string[];
        reasons?: never;
      };
    }
  | {
      action: Exclude<CandidateActivityLogStatus, 'CAMPAIGN_CANDIDATE_LABEL_CHANGED'>;
      metadata: {
        reasons: string[];
        added_labels?: never;
        original?: never;
        removed_labels?: never;
      };
    };

type CandidateActivityLogConditionalProps =
  | {
      action: Extract<CandidateActivityLogStatus, 'CAMPAIGN_CANDIDATE_CONTACTED'>;
      candidate_notification?: CandidateNotification;
    }
  | {
      action: Exclude<CandidateActivityLogStatus, 'CAMPAIGN_CANDIDATE_CONTACTED'>;
      candidate_notification?: never;
    };

export type CandidateActivityLog = CandidateActivityLogConditionalProps &
  CandidateActivityLogMetadata & {
    id: number;
    campaign: Pick<CampaignResponse, 'name'>;
    es_person_id: string;
    action: CandidateActivityLogStatus;
    tenant_id: number;
    tenant_domain: string;
    user: ActivityLogUser;
    created_at: string;
    source: string;
  };

export type GetSearchResultsParams = BasePagination & {
  campaignId: number;
  cancelToken?: CancelToken;
};

/////////////////////////////
/// Params
/////////////////////////////

export type NotifyCandidateParams = {
  campaign_id: CampaignResponse['id'];
  candidate_id: string;
  send_method: NotifyCandidateMethod;
  data: NotifyCandidateData;
};

export type GetCandidateActivityLogParams = {
  candidate_id: string;
};

export type GetCampaignsParams = {
  search?: string;
  legacy_assigment_id?: number;
};

export type ChangeOwnerParams = {
  campaignId: number | string;
  userId: number | string;
};

export type GetCampaignCandidateCVParams = {
  campaignId: string;
  candidateId: string;
  options: any;
};

export type GetCampaignCandidateCVURLParams = {
  cvFileId: string;
  shouldUseRedact?: boolean;
};

export type GetCampaignCandidateParams = {
  campaignId: string;
  candidateId: string;
};

export type GetCampaignCandidatesTabParams = PaginatedFilters<{
  id: number;
  status: CandidateStatus;
  labels?: string[];
  cancelToken?: CancelToken;
}>;

export type CreateTextSearchParams = {
  job_description: string;
};

export type CreateAtsSearchParams = {
  ats_id: string;
};

/////////////////////////////
/// Responses
/////////////////////////////

export type NotifyCandidateResponse = {
  status: NotifyCandidateStatus;
};

export type CampaignCandidateSaveNotesResponse = {
  id: number;
  content: string;
  created_at: Date;
  updated_at: Date;
  es_person_id: string;
  campaign: number;
  created_by: {
    full_name: string;
    initials: string;
  };
};

export type CampaignCandidateLabelsResponse = {
  id: number;
  reasons: string[];
};

export type CandidateActionsResponse = Record<
  CandidateStatus,
  {
    actions: CandidateActionFriendly[];
    extra_options?: Partial<Record<CandidateActionFriendly, CandidateSecondaryActionFriendly[]>>;
  }
>;

/////////////////////////////
/// Handlers
/////////////////////////////

export type GetCampaignsHandler = (params: GetCampaignsParams) => BaseCallback<CampaignResponse[]>;

export type ChangeOwnerHandler = (params: ChangeOwnerParams) => BaseCallback<CampaignResponse>;

export type GetCampaignHandler = (campaignId: number | string) => BaseCallback<CampaignResponse>;

export type GetInitialCampaignHandler = () => BaseCallback<CampaignResponse>;

export type CreateCampaignHandler = (data: Partial<CampaignResponse>) => BaseCallback<CampaignResponse>;

export type UpdateCampaignHandler = (
  campaignId: number,
  data: Partial<CampaignResponse>,
) => BaseCallback<CampaignResponse>;

export type GetSearchResultsHandler = (params: GetSearchResultsParams) => BaseCallback<any>;

export type GetCampaignsPageHandler = (
  params: PaginatedFilters<CampaignResponse>,
) => BaseCallback<PaginatedResponse<CampaignResponse>>;

export type NotifyCandidateHandler = (params: NotifyCandidateParams) => BaseCallback<NotifyCandidateResponse>;

export type GetFacetsHandler = (id: number | string) => BaseCallback<CampaignFacetsResponse>;

export type GetCampaignsOverviewHandler = (params: {
  status: CampaignOverviewStatus;
  limit: number;
  offset: number;
  name?: string;
  /**
   * If passed, it'll only fetch campaigns owned by this user id
   */
  user_id?: number;
}) => BaseCallback<PaginatedResponse<CampaignOverviewResponse>>;

export type GetCandidateActivityLogHandler = (
  params: PaginatedFilters<GetCandidateActivityLogParams>,
) => BaseCallback<PaginatedResponse<CandidateActivityLog>>;

export type GetCampaignCandidateCVHandler = (params: GetCampaignCandidateCVParams) => BaseCallback<Blob | undefined>;
export type GetCampaignCandidateCVURLHandler = (
  params: GetCampaignCandidateCVURLParams,
) => BaseCallback<{ url: string }>;
export type GetFileHandler = (url: string) => BaseCallback<Blob | undefined>;

export type DownloadCampaignCandidateCVHandler = (
  campaignId: number,
  candidateId: string,
  name?: string,
  fileExtension?: string,
) => Promise<void>;

export type PostApproveCampaignCandidateHandler = (params: { campaignId: number; candidateId: string }) => Promise<any>;

export type GetCampaignRatedCandidatesHandler = (
  params: GetCampaignCandidatesTabParams,
) => BaseCallback<PaginatedResponse<CampaignCandidateResponse>>;

export type GetCampaignCandidateHandler = (
  params: GetCampaignCandidateParams,
) => BaseCallback<CampaignCandidateResponse>;

export type GetCampaignCandidatesStatsHandler = (id: number) => BaseCallback<ICampaign.Stats>;

export type GetCampaignCandidateNotesHandler = (params: {
  es_person_id: string;
}) => BaseCallback<CampaignCandidateSaveNotesResponse[]>;

export type PostCampaignCandidateNotesHandler = (params: {
  es_person_id: string;
  notes: string;
  campaign_id: number;
}) => BaseCallback<CampaignCandidateSaveNotesResponse>;

export type PutCampaignCandidateNotesHandler = (params: {
  es_person_id: string;
  note_id: number;
  notes: string;
  campaign_id: number;
}) => BaseCallback<CampaignCandidateSaveNotesResponse>;

export type DeleteCampaignCandidateNotesHandler = (params: {
  es_person_id: string;
  note_id: number;
}) => EmptyResponseCallback;

export type PutCampaignCandidateSaveLabelsHandler = (params: {
  es_person_id: string;
  campaign_id: number;
  labels: string[];
}) => BaseCallback<CampaignCandidateLabelsResponse>;

export type GetDistinctedLabelsHandler = () => BaseCallback<string[]>;

export type GetCampaignTemplatesHandler = (params: {
  search: string;
  limit?: number;
}) => BaseCallback<PaginatedResponse<CampaignResponse>>;

export type CopyCampaignTemplatesHandler = (params: { templateId: number }) => BaseCallback<CampaignResponse>;

export type GetCandidateActionsHandler = () => BaseCallback<CandidateActionsResponse>;

export type CreateTextSearchHandler = ({ job_description }: CreateTextSearchParams) => BaseCallback<CampaignResponse>;

export type CreateAtsSearchHandler = ({ ats_id }: CreateAtsSearchParams) => BaseCallback<CampaignResponse>;

export type CandidateNotification = {
  id: number;
  created_at: string;
  send_method: HistoryContactMethod;
  message: string;
  subject: string;
  sign_off: string;
  status: string;
};
